<template>
  <div style="padding: 10px">
    <el-row :gutter="10">
      <el-col :span="12">
        <el-card>
          <div id="praiseChart" :style="{width: '100%', height: '600px'}"></div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div id="readChart" :style="{width: '100%', height: '600px'}"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "EndHome",
  data() {
    return {

    }
  },
  mounted() {
    // 基于准备好的dom，初始化echarts实例
    let praiseChart = this.$root.echarts.init(document.getElementById('praiseChart'))
    let readChart = this.$root.echarts.init(document.getElementById('readChart'))
    let option = {
      title: {
        text: '各用户点赞统计图',
        left: 'left'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        trigger: 'item'
      },
      toolbox: {
        show: true,
        feature: {
          mark: {show: true},
          dataView: {show: true, readOnly: false},
          restore: {show: true},
          saveAsImage: {show: true}
        }
      },
      series: [
        {
          name: '点赞',
          type: 'pie',
          radius: [50, 250],
          center: ['50%', '50%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 8
          },
          data: []
        }
      ]
    }
    request.get("/article/count").then(res => {
      let o1 = JSON.parse(JSON.stringify(option));
      for (let k1 in res.data.praiseCount) {
        o1.series[0].data.push({name: k1, value: res.data.praiseCount[k1]})
      }

      // 绘制图表
      praiseChart.setOption(o1);

      let o2 = JSON.parse(JSON.stringify(option));
      o2.title.text = '各用户阅读统计图'
      o2.series[0].name = '阅读'
      for (let k2 in res.data.readCount) {
        o2.series[0].data.push({name: k2, value: res.data.readCount[k2]})
      }
      // 绘制图表
      readChart.setOption(o2);

    })
  }
}
</script>

<style scoped>

</style>
